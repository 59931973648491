(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/service-with-retrial/assets/javascripts/with-retrial.js') >= 0) return;  svs.modules.push('/components/marketplace-data/service-with-retrial/assets/javascripts/with-retrial.js');
"use strict";


const {
  errorCodeHandler
} = svs.components.marketplaceData;
const {
  helpers
} = svs.components.marketplaceData.serviceWithRetrial;
const withRetrial = function (service, options) {
  let onFailedAttempt = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : () => null;
  let callback = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : () => null;
  let attempt = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : -1;
  if (typeof service !== 'function') {
    throw new Error('The service provided is not a function');
  }
  service(...options.args, (error, success) => {
    if (error) {
      if (options.retrialWarnings.indexOf(errorCodeHandler.getWarning(error.code)) > -1) {
        if (attempt === options.retrials) {
          callback(error);
        } else {
          onFailedAttempt(error, attempt + 1);
          setTimeout(() => withRetrial(service, options, onFailedAttempt, callback, ++attempt), helpers.resolveRetrialDelay(options.delay, attempt));
        }
      } else {
        callback(error);
      }
    } else {
      callback(undefined, success);
    }
  });
};
setGlobal('svs.components.marketplaceData.serviceWithRetrial.withRetrial', withRetrial);

 })(window);