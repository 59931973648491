(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/service-with-retrial/assets/javascripts/helpers/resolve-retrial-delay.js') >= 0) return;  svs.modules.push('/components/marketplace-data/service-with-retrial/assets/javascripts/helpers/resolve-retrial-delay.js');
"use strict";


const resolveRetrialDelay = function (delay) {
  let attempt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return delay ? (delay.base || 0) + (delay.increment ? delay.increment * attempt : 0) : 0;
};
setGlobal('svs.components.marketplaceData.serviceWithRetrial.helpers.resolveRetrialDelay', resolveRetrialDelay);

 })(window);